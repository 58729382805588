import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Player } from "video-react"

import {
  Container,
  makeStyles,
  Paper,
  List,
  ListItem,
  IconButton,
} from "@material-ui/core"

import "../components/layout.css"
import SEO from "../components/seo"
import BgImg from "../../static/images/mainBg-mini.jpg"

import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"

import "../../node_modules/video-react/dist/video-react.css"

const windowGlobal = typeof window !== "undefined" && window

const useStyle = makeStyles(theme => ({
  direction: "rtl",
  typography: {
    fontFamily: "Cairo, Arial",
  },
  ".MuiContainer-root": {
    color: "red !important",
  },
  mainContainer: {
    padding: "0.5rem 0 0.4rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
    backgroundImage: `url(${BgImg})`,
  },
  playList: {
    overflow: "hidden scroll",
    maxHeight: 350,
    width: "100%",
    "& li": {
      paddingTop: 12,
      paddingBottom: 12,
    },
    "& li:hover": {
      backgroundColor: "#f0f8ff",
      cursor: "pointer",
    },
  },
  playingNow: {
    backgroundColor: "#f0f8ff",
  },
  audiosList: {
    width: "100%",
  },
  downloadBtn: {
    position: "absolute",
    right: 8,
  },
}))

function Videos() {
  const classes = useStyle()
  const { i18n } = useTranslation()
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0)
  const [dataFromUrl, setDataFromUrl] = useState()
  const VideoTest = useRef(null)

  useEffect(() => {
    i18n.changeLanguage("en")
  }, [i18n])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.location.search.substring(5).includes(",")) {
        setDataFromUrl(
          windowGlobal.location.search.substring(
            5,
            windowGlobal.location.search.indexOf(",")
          )
        )
      } else {
        setDataFromUrl(windowGlobal.location.search.substring(5))
      }
    }
  }, [])
  let videosUrls
  if (windowGlobal) {
    if (windowGlobal.location.search.substring(5).split(",") !== " ") {
      videosUrls = windowGlobal.location.search.substring(5).split(",")
    }
  }
  const handlePlaylistItemClikc = (itemLink, itemNumber) => {
    if (windowGlobal) {
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState("videos", `?url=${itemLink}`)
      }
    }
    setDataFromUrl(itemLink)
    setCurrentMusicIndex(itemNumber)
  }

  return (
    <>
      <SEO title="Videos" lang={i18n.language} />
      <Container className={`${classes.mainContainer} mainContainerVideo`}>
        <Player src={dataFromUrl} ref={VideoTest} /* autoPlay muted */ />
        {videosUrls && videosUrls.length > 1 && (
          <Paper className={classes.playList}>
            <List>
              {videosUrls.map((item, key) => (
                <ListItem
                  key={key}
                  onClick={() => handlePlaylistItemClikc(item, key)}
                  className={`${
                    key === currentMusicIndex ? classes.playingNow : ""
                  } ${classes.audiosList}`}
                  style={{ flex: 1 }}
                >
                  <div style={{ flex: 1 }}>
                    {key + 1}-{" "}
                    {item.substring(
                      item.indexOf("uploads") + 8,
                      item.indexOf("_")
                    )}
                  </div>
                  <IconButton color="primary" aria-label="Best audio">
                    <PlayCircleFilledWhiteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Container>
    </>
  )
}

export default Videos
